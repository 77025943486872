Lyte.Mixin.register("crux-mass-action-mixin", {//No I18n
    massAction:function(type,module,options,successFn,failurFn,f_name){
		if(type=='mass_delete'){
			//some handling pending
            Lyte.registeredMixins['crux-mass-action-mixin'].showDeletePopup(module,options,successFn,failurFn,this);  
		}else if(type=='mass_update'){
			var _self = this;
			var promise={};
			if(module=="Activities"){
				promise.activities= store.findAll("field",{"module" : moduleApiMapping.Activities} , false,true , {apiVersion : "2.2"});
			}else{
				promise.module=store.findAll("layout",{"module":moduleRecordMapping[module].api_name},false,true,{apiVersion : "2.2" , moduleSet : true ,set_available_user_layout : true , subformModuleSet : true})
			}
			Lyte.resolvePromises(promise).then(function(res){
				options.fields=_self.getMassUpdateFields(module,res);
				if(options.fields.length==0){
					_cruxUtils.showCustomAlert({params : {ltPropWrapperClass : "massToolAlert", ltPropContentAlign:"center", ltPropButtonPosition : "center", ltPropSecondaryMessage : I18n.getMsg("crm.nomassupdatefield1"), ltPropButtons : [{"type":"accept","text":I18n.getMsg('crm.button.ok.got.it'),"appearance":"primary"}], ltPropButtonPosition : 'center'}});//no i18n
		        			return;
				}
				$L('crux-field-update')[0].component.massUpdatefn(options,successFn,failurFn)
			})
		}else if(type==='change_owner'){
			var a = store.peekRecord('module',moduleRecordMapping[module].id); //no i18n
			var node = $L('crux-mass-tools'); //no i18n
			// this.setData('cxPropShowModal',true);
			if(options.from==="mass_tool_from"){
				node[0].component.massChangerOwnerFn(options,successFn,failurFn);
			}else{
				debugger
				var promise={},_self=this;
				promise.module = store.findRecord("module" , moduleRecordMapping[this.data.module].id , undefined , undefined,undefined , {getMeta : true});
				if(!customViewObject.doMassActioninScheduler){
					promise.owner_data = a.$.triggerAction('owner_config_state',{type : 'GET'},{feature_name : f_name ? f_name : 'change_owner'});
					// promise.profile = store.findRecord("profile", store.peekRecord("user" , Crm.userDetails.USER_ID).profile.id) //no i18n
				}
				Lyte.resolvePromises(promise).then(function(res){
					if(!customViewObject.doMassActioninScheduler){
						if(!Object.keys(res.owner_data).length){
							debugger
							res.owner_data = _self.getDefaultRelatedList(module);
						}
					}else{
						res.owner_data={};
					}
				// _self.setData('ownerData',res.owner_data)
				node[0].component.getOwnerfig=true
				node[0].component.setData('ownerData',res.owner_data)
				node[0].component.massChangerOwnerFn(options,successFn,failurFn);
				},function(res){
					if(res.status===403){
						Lyte.Component.render('crux-permission-denied-alert',{cxPropReason:'You do not have sufficient permissions to perform this operation. Contact your administrator.',cxPropShow:true},"body")
					}
				})
			}
		}
	},
	getMassUpdateFields:function(module,data){
		debugger
		var fields;
		if(data==undefined){
			var modInfo=store.peekRecord("module",moduleRecordMapping[this.data.cxPropModule].id)

			fields=Lyte.deepCopyObject(modInfo.fields);
			// var modInfo = store.peekRecord('module',moduleRecordMapping[module].id);
		    // fields = Lyte.deepCopyObject(modInfo.fields);
			}
		else{
			if(this.data.cxPropModule=="Activities"){
				fields=Lyte.deepCopyObject(data.activities);
			}else{
				fields=Lyte.deepCopyObject(data.module[0].fields);
				var data_length=data.module.length;
				for(var l=1;l<data_length;l++){
					var layoutFields=data.module[l];
                    var dataFields=this.loopFnction3(layoutFields,fields);
					fields.push(...Lyte.deepCopyObject(dataFields))
				}
			}
           
			
		}
		var fieldLen = fields.length;
		for(var k = 0;k < fieldLen;k++){
			fields[k].zcqa = "select_" + fields[k].api_name;
			var showtype = [0,2,9,17];
			if(!fields[k].visible || fields[k].read_only || ( fields[k].api_name !== "Campaign_Source" || (!fields[k].mass_update && fields[k].custom_field) ) && ( showtype.indexOf(fields[k].show_type) !== -1  || !fields[k].mass_update) || (customViewObject.doMassActioninScheduler) && (fields[k].api_name === 'Layout' || fields[k].api_name === "Pipeline")){
				fields.splice(k,1);
				k--;
				fieldLen--;
			}

		}
		return fields
	},
    loopFnction3:function(layoutFields,fields){
       return layoutFields.fields.filter(function(field){ return !fields.filter(function(innerfield){ return innerfield.id === field.id})[0]})
    },
	getDefaultRelatedList:function(module){
		var data = {"Tasks":true,"Calls":true,"Events":true}  //no i18n
		if(module==="Accounts"){
			data.Contacts = true ; data.Deals = true;
		}else if(module==="Contacts"){
			data.Deals = true;
		}
		return {"owner_config_state": [data]};
	},
    showDeletePopup:function(module,options,successFn,failurFn,_self){
        var node = $L('crux-mass-tools',_self.$node).prevObject;
        node[0].component.deletePopup(options,successFn,failurFn)
    },
    handleUpdateSuccess:function(data , _self , successFn){
		debugger
		var message = false , partialS = 0 , partialF = 0 , lockedC = 0; data = data.data;
		data.forEach(function(item){
			if(item.message === "picklist not found in record layout"){
				message = true;
				partialF++;
			}else if(item.message === "Sorry, you cannot perform this operation as the record is locked."){//No I18n
				lockedC++;
			}else if(item.code === "SUCCESS"){ //no i18n
				partialS++;
			}
		})
		var remFailureCount = data.length - (partialF + lockedC + partialS);
		if(partialS === data.length){
			_cruxUtils.showCustomMessage({id : 'massUpdate', params : {ltPropType : 'success',  ltPropMessage : "Record(s) updated Successfully", ltPropDuration : '4000'}}); //no i18n
			return true;
		}else if(remFailureCount || (partialF && lockedC)){
			var totalFailCount = remFailureCount + lockedC + partialF ;
			message=`${partialS} record(s) updated successfully. Remaining ${totalFailCount} record(s) are not updated as those are either locked or inaccessible.`
			// message = I18n.getMsg('crm.massupdate.bulk.records.failure',[ partialS , totalFailCount])
		}else if(!remFailureCount){
			if(partialF){
				if(partialS){
					message = I18n.getMsg('crm.massupdate.partial.sucess.msg',partialS);
				}
				message += I18n.getMsg('crm.massupdate.multiple.layout.error.msg',[partialF,I18n.getMsg('crm.label.picklist')]);
			}else{
				message = I18n.getMsg('crm.massupdate.bulk.lock.failure',[partialS,lockedC]);
			}
		}
		_cruxUtils.showCustomAlert({id : 'massUpdateLockMixed', params : {ltPropWrapperClass : "massToolAlert", ltPropContentAlign:"center",ltPropCloseOnEscape : false ,  ltPropButtonPosition : "center", ltPropSecondaryMessage : message, ltPropButtons : [{"type":"accept","text":"Ok,got it","appearance":"primary","cxPropZcqa":"massUpdateLockMixed"}], ltPropButtonPosition : 'center'},accept : function(){successFn(_self,true)}});//no i18n
		return false;

	},
    handleUpdateErrors : function(data){
	    var message ,len = 0 ;
    	data = JSON.parse(data.response)
		if(data.data[0].code === "NO_PERMISSION"){
			Lyte.Component.render('crux-permission-denied-alert',{cxPropReason:'You do not have sufficient permissions to perform this operation. Contact your administrator.',cxPropShow:true},"body")
			return false;
		}else if(data && data.data && data.data[0]){
			len = data.data.length;
			for(var i = 0 ; i < len ; i++){
				if(message !== undefined && message !== data.data[i].message){
					_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error',ltPropClass:"",  ltPropMessage : I18n.getMsg('crm.massupdate.record.failure'), ltPropDuration : '4000'}}); //no i18n
					return true;
				}
				message = data.data[i].message;
			}
			if(data.data[0].message === "Sorry, you cannot perform this operation as the record is locked."){//No I18n
				_cruxUtils.showCustomMessage({id : 'massUpdateLock', params : {ltPropType : 'error', ltPropMessage : I18n.getMsg("crm.massupdate.record.lock.failure") , ltPropDuration : '3000'}});
				return false;;
			}
			else if(data.data[0].message === 'picklist not found in record layout' || data.data[0].message === 'picklist not found' || data.data[0].message === "Pipeline doesn't contain the Stage"){
				_cruxUtils.showCustomAlert({params : {ltPropWrapperClass : "massToolAlert",ltPropCloseOnEscape : false ,  ltPropContentAlign:"center", ltPropButtonPosition : "center", ltPropSecondaryMessage : I18n.getMsg("crm.massupdate.single.layout.error.msg",I18n.getMsg('crm.label.picklists')), ltPropButtons : [{"type":"accept","text":I18n.getMsg('crm.button.ok.got.it'),"appearance":"primary"}], ltPropButtonPosition : 'center'}})//no i18n
				return false;
			}else{
				if(data.data[0].code === "INVALID_DATA" ){
					if(data.data[0].message === "This field cannot be updated in the Mass Update"){
						message = I18n.getMsg('crm.massupdate.field.failure')
					}else if(data.data[0].message === "the id given seems to be invalid" || data.data[0].details && Object.keys(data.data[0].details).includes("id")){
						message = I18n.getMsg('crm.massupdate.record.failure');
					}else{
						message = I18n.getMsg('crm.massupdate.field.failure')
					}
				}else if(data.data[0].code === "RECORD_IN_BLUEPRINT"){
					message = I18n.getMsg('crm.massupdate.blueprint.failure')
				}else{
					message = I18n.getMsg('crm.massupdate.record.failure');
				}
				_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error',ltPropClass:"",  ltPropMessage : message, ltPropDuration : '4000'}}); //no i18n
				return true;
			}
		}else{
			_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error',ltPropClass:"",  ltPropMessage : I18n.getMsg('error.unknown.problem'), ltPropDuration : '4000'}}); //no i18n
			return true;
		}
    },
	handleDeleteErrors : function(data){
    	if(data.status === 403 || data.status === 401){
			Lyte.Component.render('crux-permission-denied-alert',{cxPropReason:'You do not have sufficient permissions to perform this operation. Contact your administrator.',cxPropShow:true},"body")
			return false;
		}else if(customViewObject.doMassActioninScheduler){
			var process = I18n.getMsg("crm.mass.actions.delete");
			var errormsg = I18n.getMsg("crm.mass.actions.scheduled.fail",[process]);
			_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error', ltPropMessage : errormsg, ltPropDuration : '4000'}}); //no i18n
		}else{
			var res = JSON.parse(data.response) , smessage , msgs = ['The product cannot be deleted as it is involved in inventory record' , "The product cannot be deleted as it is involved in inventory record or product configurator or price rule" ]; //no i18n
			if(res.message){
				res = {data : [res]};
			}
			if(res && res.data && res.data[0].code === "CANNOT_DELETE"){
				Lyte.Component.render('crux-permission-denied-alert',{cxPropReason:'You do not have sufficient permissions to perform this operation. Contact your administrator.',cxPropShow:true},"body")
				return false;
			}
			if(res && res.data && res.data.length > 1){
				var len = res.data.length;
				for(var i = 0 ; i < len ; i++){
					if(smessage !== undefined && smessage !== res.data[i].message){
						_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error', ltPropMessage : I18n.getMsg("crm.massdelete.record.failure") , ltPropDuration : '4000'}}); //no i18n
						return true;
					}
					smessage = res.data[i].message;
				}
			}
			if(res && res.data[0] && res.data[0].message === "Sorry, you cannot perform this operation as the record is locked."){//No I18n
				_cruxUtils.showCustomMessage({id : 'massDeleteLock', params : {ltPropType : 'error', ltPropMessage : I18n.getMsg("crm.massdelete.record.lock.failure") , ltPropDuration : '3000'}});
				return false;;
			}else if(res && res.data && res.data[0] && msgs.indexOf(res.data[0].message) !== -1){
				var invMods = [moduleRecordMapping.Quotes.plural_label , moduleRecordMapping.SalesOrders.plural_label , moduleRecordMapping.PurchaseOrders.plural_label, moduleRecordMapping.Invoices.plural_label];
				if(Crm.isCpqActivated()){
					smessage = I18n.getMsg('crm.cpq.cannotdelete.rules.list' ,  [moduleRecordMapping.Products.singular_label].concat(invMods))
				}else{
					
					smessage = I18n.getMsg("crm.cannot.delete.products.reason.listview" ,[moduleRecordMapping.Products.singular_label].concat(invMods));
				}
				_cruxUtils.showCustomAlert({params : {ltPropWrapperClass : "massDeleteCPQAlert",ltPropCloseOnEscape : false, ltPropContentAlign:"center", ltPropButtonPosition : "center", ltPropHeading : I18n.getMsg("crm.cannot.delete.products.listView" , moduleRecordMapping.Products.singular_label), ltPropSecondaryMessage : smessage, ltPropButtons : [{"type":"accept","text":I18n.getMsg('crm.button.ok'),"appearance":"primary"}], ltPropButtonPosition : 'center'}, show : function(node){node.childComp.querySelector('.alertPrimaryMsg').classList.add('f22, center')}}); //no i18n
				return false;
			}else{
				if(data.status === 400 && res.data && (res.data[0].code === "INVALID_DATA" || res.data[0].code === "NOT_APPROVED")){
					_cruxUtils.showCustomMessage({id : '', params : {ltPropType : 'error', ltPropMessage : I18n.getMsg("crm.massdelete.record.failure") , ltPropDuration : '4000'}}); //no i18n
					return true;
				}
			}
		 }
    },
	fieldYieldMapping:function(headerFields, cxPropIsResizeEnabled, moduleInfo , from){
		var fieldUiType=Object.assign({}, this.data.cxPropDefaultUiTypeCruxMapping);
		Object.assign(fieldUiType, {20 : "user", 127 : "lookup",133 : 'lookup',132:"lookup"});//No I18n
		if(from === "mass-tools"){
			Object.assign(fieldUiType, {33 : "text" });//No I18n
		}
		moduleInfo = moduleInfo === undefined ? this.data.cxPropModuleInfo : moduleInfo; //No I18n
		
		var sort_by =store.peekRecord('custom_view',this.data.cxPropCvid);
		sort_by=sort_by && sort_by.sort_by?sort_by.sort_by.id:undefined;
		var customfields =[];
		
		var fieldMapping = {};
		var fields=moduleInfo.fields;
		var dpFields=this.data.cxPropDisplayField;
		var plEventMod =  "Activities" === moduleInfo.module_name || moduleInfo.module_name === 'Events';//No I18n
		var	head=dpFields[this.data.cxPropModule];
		head = head === undefined ? [] : head;
		var innerComp = $L("crux-list-view",this.$node)[0]//No I18N
		var cvfieldlen = headerFields.length;
	
		var customfields =[];
		var fieldlen = fields.length;
		var headLen = head.length;
		var listviewComp=$L('crux-list-view',this.$node);
		var isWidthNeeded = false;
		var setSortBy = false;
		var cv_width_map = this.getData("cv_mod_fields")? this.getData("cv_mod_fields") : new Map();//No I18N
		for (var i = 0; i < cvfieldlen; i++){
			if(headerFields[i]._width || cv_width_map.size > 0){
				isWidthNeeded = true;
				break;
			}
		}

		for (var i=0; i< cvfieldlen; i++){
		    for (var j=0; j< fieldlen; j++){
				if(headerFields[i].api_name == fields[j].api_name && fields[j].visible && fields[j].available_in_user_layout && fields[j].ui_type != 51) {
					if(fields[j].show_type === 0 && !(fields[j].column_name === "FULLNAME" || fields[j].column_name === "PROCESSINGBASIS" || fields[j].column_name === "SE_STATUS")){
						continue;
					}
					// if(fields[j].api_name == "Full_Name" && (moduleInfo.module_name == "Contacts" || moduleInfo.module_name == "Leads")){
					// 	// fields[j].field_label = I18n.getMsg("custommodule.crmfield.fl.name",moduleInfo.singular_label);
	   				// }
					var isCustomModule = false;
					// if(fields[j].api_name === "zia_suggested_user_1"){
					// 	fields[j].api_name = "zia_suggested_user";
					// }
					if(this.data.cxPropIsLinkToNotSupported && fieldUiType[fields[j].ui_type]==='lookup'){
						fieldMapping[fields[j].api_name]="text";
					}else{
						fieldMapping[fields[j].api_name] = fieldUiType[fields[j].ui_type];
					}
					if(!this.data.cxPropIsLookupYield && (fields[j].yieldName==="lookup" || fields[j].yieldName==="multi_module_lookup")){
						delete fields[j].yieldName;
					}
				if( fields[j].data_type == "lookup"){
		        	if(fields[j].api_name && fields[j].api_name.indexOf("Touchpoint_Email") > -1 && moduleInfo.module_name.startsWith("PathFinder")) {
		        		fields[j].yieldName = "pf_email_lookup";
			        }else {
			        	// fields[j].yieldName = "lookup";
						if(this.data.cxPropIsLinkToNotSupported){
							fields[j].cxTypeMapping="text";
						}else{
							fields[j].cxTypeMapping = "lookup";
						}
			        }
		        }
				
				for (var k =0 ; k <headLen; k ++){
		        	if(head[k] == fields[j].api_name){
		        		// fields[j].yieldName = "lookup";
						if( !this.data.cxPropIsLookupYield && (fields[i].yieldName==="lookup" || fields[j].yieldName==="multi_module_lookup")){
							delete fields[i].yieldName;
						}
						if(this.data.cxPropIsLinkToNotSupported){
							fields[j].cxTypeMapping = "text";
							fieldMapping[fields[j].api_name]="text";
						}else{
							fields[j].cxTypeMapping = "lookup";
							fieldMapping[fields[j].api_name]="lookup";
						}
		        	}
		        }
				 fields[j].list_display_label = fields[j].field_label;
				 if(fields[j].ui_type == 209){
		        	fields[j].list_display_label = I18n.getMsg("crm.label.small.tags");
		        }
		        // if(!fields[j].sortable)
				// {
				//  	fields[j].cxPropClass = "cursorDefault"
				//  	if(window.clientPortalName) {
				//  		fields[j].cxPropClass += " noSortFieldOption"
				//  	}
				// }

				if(fields[j].ui_type == 2 && fields[j].column_name == "TYPE" ){
					fields[j].yieldName = "campaign-type"
				}
				else if(fields[j].data_type == "multi_module_lookup"){
					fields[j].yieldName = "multi_module_lookup";
				}
				else if(fields[j].ui_type === 2 && moduleInfo.module_name.indexOf("PathFinder") !== -1 && fields[j].column_name === "STATE_ID") {
					fields[j].yieldName = "current_state";
					fields[j].disableExtraValue = true;
				}
				else if(fields[j].column_name == "BEST_TIME"){
					fields[j].yieldName = "best_time";
				}
				else if (plEventMod && fields[j].enable_colour_code === true){
					fields[j].yieldName = "eventtypecolour";
					fields[j].cxPropClass = "cxLvPR";
				}
				else if(fields[j].column_name == "ACTIVITYTYPE"){
					fields[j].yieldName = "activity_type";
				}
				else if(fields[j].ui_type == 999){
					fields[j].yieldName = "territory";
				}
				else if(fields[j].ui_type == 8){//ui
					fields[j].cxPropTooltip = true;
					fields[j].style = "max-width :  150px";
				}

				if(headerFields[i].api_name === 'Owner' && (headerFields[i].id !== fields[j].id)){
				  isCustomModule = true;
				}
	
				if(!isCustomModule){
					var fieldObj = fields[j];
					delete fieldObj.cxPropClass
					// fieldObj.cxPropClass += " cellWrap";//no I18n
					if(head.includes(headerFields[i].api_name)){
    					fieldObj.cxPropClass += " cxLvAlphaSearchIncl" ;//no I18n
    					if(headerFields[i].api_name !== "Last_Name"){
    						fieldObj.cxPropClass += " cxLvNameLinkColumn" ;//no I18n
    					}
    				}
    				if(fieldObj.ui_type && (fieldObj.ui_type === 3 || fieldObj.ui_type === 110)) {
    					fieldObj.cxPropClass += " cxLvTextAreaElem" ;//no I18n
    				}

					var tmpWidth = 0;
					if(cxPropIsResizeEnabled){
						Lyte.objectUtils( fieldObj , "add" , "cxPropResize" , "enable" );
						// fieldObj.cxPropResize = 'enable';//no I18n
						if(cv_width_map.has(fieldObj.id)){
							tmpWidth = cv_width_map.get(fieldObj.id);
							fieldObj.style = "width: " + tmpWidth + "px;" ;//no I18n
						}else if(headerFields[i]._width ){
							tmpWidth = parseInt(headerFields[i]._width);
							fieldObj.style = "width: " + tmpWidth + "px;" ;//no I18n
						}else{
							fieldObj.style = "" ;
						}
						if(isWidthNeeded && fieldObj.style === ""){
							tmpWidth = this.getDefaultWidthForField(fieldObj.ui_type);
							fieldObj.style = "width: " + tmpWidth + "px;" ;
						}
						if(tmpWidth !== 0){
							Lyte.objectUtils( fieldObj , "add" , "width" , tmpWidth + "px" );
							// fieldObj.width = tmpWidth + "px";
						}
					}	
					if(fieldObj.ui_type === 209) {
						tmpWidth = tmpWidth === 0 ? this.getDefaultWidthForField(fieldObj.ui_type) : tmpWidth;
						Lyte.objectUtils( fieldObj , "add" , "width" , tmpWidth + "px" );
						// fieldObj.tagWidth = tmpWidth + 'px';//no I18n
						fieldObj.cxPropClass += " cxLvTableTagElem" ;//no I18n
					}
					// if(fieldObj.ui_type === 999){
					// 	fieldObj.cxPropClass += " nlv_data_territory" ;//no I18n
					// }
	
					if(headerFields[i]._pin){
    					fieldObj.fixed = 'enable'; //No I18n
    					fieldObj.cxPropPinned=true;
						if(headerFields[i]._pin_order){
							fieldObj.cxPropPinOrder=headerFields[i]._pin_order;
						}
    				} else {
						delete fieldObj.fixed;
    					fieldObj.cxPropPinned=false;
    				}
					if(sort_by && sort_by === fieldObj.id){
						fieldObj.cxPropClass += " cxLvSortAppliedFld"; //No I18N
						if(listviewComp){
							this.setData('cxPropSortByComp',store.peekRecord('custom_view',this.data.cxPropCvid).sort_by);
							setSortBy = true;
						}
					}
					// if((fieldObj.ui_type === 2 || fieldObj.ui_type === 26) && fieldObj.enable_colour_code){
					// 	fieldObj.cxPropClass += " nlv_data_picklist" ;//no I18n
					// }
	
					customfields.push(fieldObj);
					break;
	
				}
			}
			}
		}
		if(!setSortBy){
			if(listviewComp) {
				this.setData('cxPropSortByComp', undefined) //No I18N
			}
		}

		customfields.sort((field1, field2) => Number(field2.cxPropPinned) - Number(field1.cxPropPinned));
		return {'customfields':customfields,'fieldMapping':fieldMapping};//No I18n
	},
	getDefaultWidthForField : function(uiType){
		return uiType === 209 || uiType === 3 || uiType === 110 ? 400 : 200;
	},
	// list view changes
	selectedEntity:function(element,cvrecords,selectedIds,prevSelectedId,from,targetRow,selectedObj){
		var selectedIds =selectedIds?selectedIds: this.getData("selectedIds");//No I18n
		prevSelectedId=this.getData('prevSelectedId');
		var customOuter;
		var targetclass=element._callee.component.data.ltPropClass
		// var targetclass = targetRow && targetRow.length ? targetRow[0].querySelector('.crmlistview-canvas-selectionbox').ltProp("class") : $("#"+element.id+" lyte-checkbox")[0].ltProp("class"); //eslint-disable-line @zoho/webperf/no-complex-selector
		// var targetclass=  $("#"+element.id+" lyte-checkbox",this.$node)[0].component.data.ltPropClass
		if(event && event.shiftKey == true && prevSelectedId && prevSelectedId != element.id  && $L("#selectEntity_"+prevSelectedId,this.$node)[0]){
			var startIndex =$L("#selectEntity_"+prevSelectedId,this.$node)[0].component.data.dataRecnum-1; //No I18n
			var endIndex =$L("#selectEntity_"+element.id,this.$node)[0].component.data.dataRecnum-1; //No I18n
			var fromIndex = this.data.startIndex;//No I18n
			startIndex = startIndex-fromIndex;
			endIndex = endIndex-fromIndex;
			if(targetclass == "cxListVwCustomCheckBox"){
				if(endIndex<startIndex){
					for(var i=startIndex; i>=endIndex; i--){
						if(selectedIds.length==this.data.cxPropMaxSelectCount){
							_cruxUtils.showCustomAlert({ params : { ltPropPrimaryMessage : I18n.getMsg("crm.listview.maximum.records.alert",this.data.cxPropMaxSelectCount) } });
							break;
						}
						if(this.waitingForApproval(cvrecords[i], undefined))
						{
							continue;
						}
						var tempId = cvrecords[i].id;
						if(!selectedIds.includes(tempId)){
							this.selectEntityCommon(tempId, "cxListVwCustomCheckBox",undefined,undefined,customOuter,targetRow);
						}
					}
				}
				else{
					for(var i=startIndex; i<=endIndex; i++){
						if(selectedIds.length==this.data.cxPropMaxSelectCount){
							_cruxUtils.showCustomAlert({ params : { ltPropPrimaryMessage : I18n.getMsg("crm.listview.maximum.records.alert",this.data.cxPropMaxSelectCount) } });
							break;
						}
						if(this.waitingForApproval(cvrecords[i], undefined))
						{
							continue;
						}
						var tempId = cvrecords[i].id;
						if(!selectedIds.includes(tempId)){
							this.selectEntityCommon(tempId, "cxListVwCustomCheckBox",undefined,undefined,customOuter,targetRow);
						}
					}
				}
				
			}
			else if(endIndex < startIndex){
				for(var i=startIndex; i>=endIndex; i--){
					var tempId = cvrecords[i].id;
					if(selectedIds.includes(tempId)){
						this.selectEntityCommon(tempId, "cxListVwCustomCheckBoxChecked",undefined,undefined,customOuter,targetRow);
					}
				}
			}
			else{
				for(var i=startIndex; i<=endIndex; i++){
					var tempId = cvrecords[i].id;
					if(selectedIds.includes(tempId)){
						this.selectEntityCommon(tempId, "cxListVwCustomCheckBoxChecked",undefined,undefined,customOuter,targetRow);
					}					
				}
			}
			selectedObj = {"selectedIds" : selectedIds, "per_page" : this.data.cxPropPerPage, "page" : this.getData("cxPropPage"), "module" : this.getData("cxPropModule"), "start_index" : this.data.startIndex,  "customview_recordlen"  : this.data.cxPropContent.len}; //No I18n
			this.observeSelectedIds(selectedObj,this.getData('isFromModal')); //No I18N
			return false;
		}	


		//shift logic
		if(selectedIds.length==this.data.cxPropMaxSelectCount &&  targetclass !== "cxListVwCustomCheckBoxChecked"){
			_cruxUtils.showCustomAlert({ params : { ltPropPrimaryMessage : I18n.getMsg("crm.listview.maximum.records.alert",this.data.cxPropMaxSelectCount) } });
		}else{
			this.selectEntityCommon(element.id,targetclass);
		}
	},
	waitingForApproval:function(rec, featureName){
		var approval = rec.$approved === false && rec.$approval.resubmit === false;
    	if((rec.$stop_processing && !("COMPLIANCE" === featureName)) || approval || rec.$in_merge === true || rec.$approval_state === "review_process_pending" ){
    		return true;
    	}
    	return false;
	},
	selectEntityCommon:function(elementId, targetclass,targetId,targetElem,customOuter,targetRow,from , selectedObj){
		var _self=this;
		var selectedIds =this.data.selectedIds;
		if(!from){
			from="listview";
		}
	
		// var trElems=$L("lyte-exptable-tr" ,$L("#"+elementId)).prevObject[0]
		var trElems = this.$node.querySelectorAll("lyte-exptable-tr[id='"+elementId+"']"); //No I18n
		trElems.forEach(function(trElem){

		var targetElem=trElem.querySelector('lyte-checkbox');
			var checkBoxSpan=targetElem.querySelector('span');
		if(targetclass=='cxListVwCustomCheckBox' && elementId != "selectAllEntity"){
			if(selectedIds.indexOf(elementId)===-1){
				Lyte.arrayUtils(selectedIds,'push',elementId);
			}
		
			targetElem.ltProp('class','cxListVwCustomCheckBoxChecked')
			checkBoxSpan.classList.remove("cxListVwCustomCheckBox"); //No I18n
			checkBoxSpan.classList.add("cxListVwCustomCheckBoxChecked"); //No I18n
			trElem.classList.add("cxLvTableRowSelected");//No I18n
		}
		else{
			if(selectedIds.indexOf(elementId) > -1){
				Lyte.arrayUtils(selectedIds, "removeAt", selectedIds.indexOf(elementId), 1);//No I18n
				
			}
			targetElem.ltProp("class","cxListVwCustomCheckBox");//No I18n
				checkBoxSpan.classList.remove("cxListVwCustomCheckBoxChecked"); //No I18n
				checkBoxSpan.classList.add("cxListVwCustomCheckBox"); //No I18n
				trElem.classList.remove("cxLvTableRowSelected");//No I18n
		}
		if(targetId!=="selectedIds" && from==="listview"){
			_self.setData("prevSelectedId",elementId); //No I18n
			selectedObj = {"selectedIds" :selectedIds, "page" : _self.data.cxPropPage, "per_page" : _self.data.cxPropPerPage, "module" : _self.getData("cxPropModule"), "start_index" :_self.data.start_index,  "customview_recordlen"  :_self.data.cxPropContent.length};
			if($L(trElem,this.$node).parents('.lyteExpTableOrigTableInnerWrap').length){
				_self.observeSelectedIds(selectedObj,_self.getData('isFromModal'));//No I18n
			}
		}
	});	
	},
	observeSelectedIds:function(selectedObj,isFromModal){
		var className = "cxListVwPartialselect";//No I18n
		var selectedIdLen = selectedObj.selectedIds.length;
		var uncheckedCheckboxCount = selectedObj.unCheckedEntitiesCount , parentContainer;
		// if( selectedObj && selectedObj.data){
		// 	parentContainer = $L("#"+selectedObj.data,this.$node);
		// }
		if(uncheckedCheckboxCount != 0 && uncheckedCheckboxCount == undefined){
			// if(selectedObj.data){
			// 	uncheckedCheckboxCount =  parentContainer.find(".cxListVwCustomCheckBox[data-cid=selectEntity]").length; //eslint-disable-line @zoho/webperf/no-attribute-selectors
			// }else{
				uncheckedCheckboxCount =   $L(".lyteExpTableOrigTableInnerWrap .lyteExpTableRowGroup .cxListVwCustomCheckBox",this.$node).length //No i18n
			// }
		}
		var checkedCheckboxCount =  $L(".lyteExpTableOrigTableInnerWrap .lyteExpTableRowGroup .cxListVwCustomCheckBoxChecked",this.$node).length ; //No i18n
		if(selectedIdLen == 0){
			className = "cxListVwCustomCheckBox";//No I18n
		}
			if((selectedObj.customview_recordlen === 1 || uncheckedCheckboxCount === 0) &&  selectedIdLen >=1){
				className = "cxListVwCustomCheckBoxChecked";//No I18n
			}
			if(checkedCheckboxCount == 0){
			className = "cxListVwCustomCheckBox";//No I18n
			}
			if(className == "cxListVwCustomCheckBoxChecked"){
				selectedObj.showSelectDiv = true;
				// this.checkSelectedDiv();
			}
			if(className === "cxListVwPartialselect" && selectedIdLen < this.data.cxPropPerPage){
				if((checkedCheckboxCount >= selectedIdLen) && uncheckedCheckboxCount !=0){
					this.setData("showSelectedDiv", false); //No I18n
				}else{
					this.setData("showSelectedDiv", selectedObj.showSelectDiv); //No I18n
				}

			}
		var tarElems =  this.$node.querySelectorAll("[id='selectCheckbox']"); //No i18n
				tarElems.forEach(function(tarElem){					
					if(tarElem){
						var targetSpan = tarElem.querySelector("span"); //No i18n
						targetSpan.classList="";
						targetSpan.classList= className;
						tarElem.ltProp("class", className); //No i18n					
					}
				});
				if(this.data.from=='listview'){
				if(className==="cxListVwCustomCheckBoxChecked"){
					if(this.data.selectViewArray.indexOf(this.data.cxPropPage)===-1 ){
						Lyte.arrayUtils(this.getData('selectViewArray') , 'push', this.data.cxPropPage);
					}
				}
				else if(className==="cxListVwCustomCheckBox" || className==="cxListVwPartialselect"){
					if(this.data.selectViewArray.indexOf(this.data.cxPropPage)>-1){
						Lyte.arrayUtils(this.data.selectViewArray, "removeAt", this.data.selectViewArray.indexOf(this.data.cxPropPage), 1);
					}
				}
				if(this.data.selectViewArray.length>0){
					this.setData("showSelectedDiv", true); 
				}else{
					this.setData("showSelectedDiv", false); 
				}
				 
				// if(this.data.per_page>=this.data.total_count){
				// 	this.setData('showSelectedDiv',false)
				// }
				
				}
				this.data.selectedIds.length?this.setData('showActionBtn',true):this.setData('showActionBtn',false)
	},
	selectAllEntity:function(clear,select, selectOnlineEntities,selectedIds , selectedObj){
		var selectedIds = this.getData("selectedIds");//No I18n
			this.setData('prevSelectedId',"");
			var selected = false , cv_records = [] , targetElem;
			targetElem=this.$node.querySelector("[id='selectCheckbox']")
			selectedObj = selectedObj ? selectedObj : {"selectedIds" : selectedIds, "page" : this.getData("cxPropPage"), "per_page" : this.data.cxPropPerPage, "module" : this.getData("cxPropModule"), "start_index" : this.getData("start_index")}; //No I18n
			var _self = this, done = false;
		 		var onlineSelected = true;

			var targetClass=targetElem ? targetElem.ltProp("class") : "cxListVwCustomCheckBoxChecked";
			cv_records = cv_records.length ? cv_records : this.data.cxPropContent; //No I18n 
			var customViewRecordsLen = cv_records.length;
			if((targetClass==='cxListVwCustomCheckBox' && !clear) || select && targetClass==='cxListVwPartialselect'){
				var toPush = [];
				if(!done){
					var displayOnlineEntities = false;
					for(var i=0; i<customViewRecordsLen; i++){
						if(selectedIds.length + toPush.length == this.data.cxPropMaxSelectCount){
							_cruxUtils.showCustomAlert({ params : { ltPropPrimaryMessage : I18n.getMsg("crm.listview.maximum.records.alert",this.data.cxPropMaxSelectCount) } });
							selectedObj.showSelectDiv = false;
							selected = false;
							break;
						}
						selected = true;
						if((!_self.waitingForApproval(cv_records[i], this.getData('featureName'))) && selectedIds.indexOf(cv_records[i].id) === -1){
							var trs =  this.$node.querySelectorAll("lyte-exptable-tr[id='"+cv_records[i].id+"']"), pushDone = false;  //No I18n
							if(selectOnlineEntities){
								onlineSelected = false;
								continue;
							}

		 				toPush.push(cv_records[i].id);
						this.loopFunctionCall(trs)
						// trs.forEach(function(tr){
						// 	var chk=tr.querySelector("lyte-checkbox");//No I18n
						// 	if(chk && chk.ltProp("class") == "cxListVwCustomCheckBox"){
						// 		chk.ltProp("class","cxListVwCustomCheckBoxChecked");  //
						// 		tr.style.backgroundColor ="setTdBorBtm";
						// 		var checkBoxSpan =  chk.querySelector("span");  //no i18n
						// 		checkBoxSpan.classList.remove("cxListVwCustomCheckBox"); //No I18n
			 			// 		checkBoxSpan.classList.add("cxListVwCustomCheckBoxChecked"); //No I18n
			 			// 		tr.classList.add("cxLvTableRowSelected");//No I18n
						// 	}
						// })
					}
					}
					if(selectedObj.per_page<_self.getData("total_count")){
						this.setData('showSelectDiv',true);
						selectedObj.showSelectDiv = true;
					}

				}
				Lyte.arrayUtils(selectedIds, "push", toPush);//No I18n
			}else{
				if(!done){
					for(var i=0; i<customViewRecordsLen; i++){
						var index = selectedIds.indexOf(cv_records[i].id) ,  pushDone = false;
						var trs =  this.$node.querySelectorAll("lyte-exptable-tr[id='"+cv_records[i].id+"']"), pushDone = false; 
						this.loopFunctionCall2(trs);
						if(!pushDone && index > -1){
							Lyte.arrayUtils(selectedIds, "removeAt", index,1);//No I18n		
							pushDone = true;
						}

					}
					var smartFilter = $L('crux-smart-filter');
					if(smartFilter.length>0){
						var smartFilterCriteria = smartFilter[0].component.getCriteria();
						if(smartFilterCriteria && smartFilterCriteria.preventSaveFilter==='competitor_alert'){
							var saveButton = $L('#saveBtn');
							if(saveButton.length>0){
								saveButton[0].component.setData('ltPropDisabled',true);
							}
						 }
					}
				}
			}
			done = true;
			var targetElems = this.$node.querySelectorAll("[id='selectCheckbox']"); //No I18n
			targetElems.forEach(function(targetElem){ 
				var checkBoxSpan = targetElem ? targetElem.querySelector("span") : undefined;  //no i18n
				if(selected && onlineSelected){
					targetElem.ltProp("class","cxListVwCustomCheckBoxChecked");//No I18n
			 		checkBoxSpan.classList.remove("cxListVwCustomCheckBox"); //No I18n
			 		checkBoxSpan.classList.add("cxListVwCustomCheckBoxChecked"); //No I18n						
				}else if(checkBoxSpan){
					checkBoxSpan.classList.remove("cxListVwCustomCheckBoxChecked"); //No I18n
					checkBoxSpan.classList.add("cxListVwCustomCheckBox"); //No I18n
					targetElem.ltProp("class","cxListVwCustomCheckBox");  //No I18n
				}
			})
			this.setData('selectedIds',selectedIds);
			if(clear){
				this.setData('selectedIds',[]);
			}
			if(this.getData("cxPropContent")){
				selectedObj.customview_recordlen  = this.getData("cxPropContent").length; //No I18n
			}
			this.observeSelectedIds(selectedObj,this.getData('isFromModal'));//No i18n
		},	
		loopFunctionCall:function(trs){
			trs.forEach(function(tr){
				var chk=tr.querySelector("lyte-checkbox");//No I18n
				if(chk && chk.ltProp("class") == "cxListVwCustomCheckBox"){
					chk.ltProp("class","cxListVwCustomCheckBoxChecked");  //
					tr.style.backgroundColor ="setTdBorBtm";
					var checkBoxSpan =  chk.querySelector("span");  //no i18n
					checkBoxSpan.classList.remove("cxListVwCustomCheckBox"); //No I18n
					 checkBoxSpan.classList.add("cxListVwCustomCheckBoxChecked"); //No I18n
					 tr.classList.add("cxLvTableRowSelected");//No I18n
				}
			})
		},
		loopFunctionCall2:function(trs){
			trs.forEach(function(tr){
				var chk = tr.querySelector("lyte-checkbox");//No I18n
				if(chk && chk.ltProp("class") == "cxListVwCustomCheckBoxChecked"){
					chk.ltProp("class","cxListVwCustomCheckBox");  //No I18N
					tr.style.backgroundColor ="";
					var checkBoxSpan =  chk.querySelector("span");  //no i18n
					checkBoxSpan.classList.remove("cxListVwCustomCheckBoxChecked"); //No I18n
					 checkBoxSpan.classList.add("cxListVwCustomCheckBox"); //No I18n
					 tr.classList.remove("cxLvTableRowSelected");//No I18n
	
				}
			});
		}

})