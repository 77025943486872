/**
 * @component crm-relatedlist
 * @author gayathiri.ks
 * @version 1.0.0
 * @summary summary about the component if any
 * @notes notes about the component if any
 */
Lyte.Component.register("crm-relatedlist", {
_template:"<template tag-name=\"crm-relatedlist\"> <lyte-event-listener event-name=\"relatedList\" on-fire=\"{{action('refreshRL')}}\"></lyte-event-listener> <crux-related-list-wrapper cx-prop-related-lists=\"{{cxPropRelatedLists}}\" cx-prop-module=\"{{cxPropModule}}\" cx-prop-layout-id=\"{{cxPropLayoutId}}\" cx-prop-entity-id=\"{{cxPropEntityId}}\" cx-prop-module-id=\"{{cxPropModuleId}}\" cx-prop-record=\"{{cxPropRecord}}\" cx-prop-bulk-request-to=\"{{cxPropBulkRequestTo}}\" related-list-action=\"{{method('relatedListActionCrmHandler')}}\" cx-prop-rl-data=\"{{cxPropRlData}}\" rl-rec-action=\"{{method('relatedRecordAction')}}\"></crux-related-list-wrapper> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"componentDynamic","position":[1]},{"type":"attr","position":[3]},{"type":"componentDynamic","position":[3]}],
_observedAttributes :["cxPropRelatedLists","cxPropModule","cxPropLayoutId","cxPropEntityId","cxPropRlData","cxPropRecord","cxPropRelatedListYield","cxPropBulkRequestTo","unusedRelatedLists"],
 
	data : function(){
		return {
			/**
			 * @componentProperty { array } cxPropRelatedLists
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropRelatedLists	 : Lyte.attr('array'),
			/**
			 * @componentProperty { string } cxPropModule
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropModule 	  	 : Lyte.attr('string'),
			/**
			 * @componentProperty { string } cxPropLayoutId
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropLayoutId		 : Lyte.attr('string'),
			/**
			 * @componentProperty { string } cxPropEntityId
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropEntityId		 : Lyte.attr('string'), 
			/**
			 * @componentProperty { object } cxPropRlData
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropRlData 		 : Lyte.attr('object'),
			/**
			 * @componentProperty { object } cxPropRecord
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropRecord 		 : Lyte.attr('object'),
			/**
			 * @componentProperty { boolean } cxPropRelatedListYield=false
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropRelatedListYield : Lyte.attr('boolean'),
			/**
			 * @componentProperty { string } cxPropBulkRequestTo
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			cxPropBulkRequestTo		: Lyte.attr('string',{default : 'moduleapiname'}),
			/**
			 * @componentProperty { array } unusedRelatedLists
			 * @author gayathiri.ks
			 * @version 1.0.0
			 */
			unusedRelatedLists 		:	Lyte.attr('array',{default : ["CHECKLISTSPERSONALITY", "ABMACCOUNTPERSONALITY" , "WEBFORMUSAGEPERSONALITY","REVIEWPROCESSLOGPERSONALITY","CASESMAILPERSONALITY","EXPEDIAPERSONALITY","INVITEESPERSONALITY",												 
																	  "SCOREPERSONALITY","EMAILSENTIMENTPERSONALITY","JOBSHEETSPERSONALITY","Appointments_Rescheduled_History__s","SURVEYPERSONALITY","SUPPORTPERSONALITY"
																	 ,"WEBINAR_POLLS_PERSONALITY","WEBINAR_QUESTION_PERSONALITY","CHILDCAMPAIGNSPERSONALITY","CIRCUITSPERSONALITY","VOCPERSONALITY","SOCIALPERSONALITY","ZINVOICEPERSONALITY"
																	,"ZEXPENSEPERSONALITY","ZSUBSCRIPTIONSPERSONALITY","PROJECTSPERSONALITY","MESSAGESPERSONALITY","EMAILSPERSONALITY","RELATEDLISTPERSONALITY","CHECKLISTSPERSONALITY",
																	"LINKEDIN_SALES_NAVIGATOR_PERSONALITY","TASKSPERSONALITY","CALLSPERSONALITY","EVENTSPERSONALITY","APPOINTMENTSPERSONALITY","TASKSHISTORYPERSONALITY","CALLSHISTORYPERSONALITY",
																	"EVENTSHISTORYPERSONALITY","APPOINTMENTSHISTORYPERSONALITY","INVITEDEVENTSPERSONALITY","LOCKINGINFORMATIONPERSONALITY"]})
		};		
	},
	init : function(){
		if(!this.data.cxPropRelatedLists){
			var visibleRLs = [] , _self = this;
			store.findAll('related_list',{module : this.data.cxPropModule , layout_id : this.data.cxPropLayoutId} , false,true,{getActions : true}).then(function(res){
				res.forEach(function(rl){
					if(rl.visible && !_self.data.unusedRelatedLists.includes(rl.personality_name)){
						visibleRLs.push(rl);
					}
				});
				_self.setData('cxPropRelatedLists',visibleRLs);
			});
		}
		window.Lyte.injectResources([networkUtils.returnDependencyFiles(['crm-detail-view-utils.js'], ResourceConstants.CRMClient)]);
	},
	actions : {
		refreshRL : function(data){
			var rlId = data.id;
			var rlMeta = store.peekRecord('related_list',rlId) , param={};
			if(rlMeta.type === 'multiselectlookup'){
				param.fields = rlMeta.fields.map((item)=>item.api_name).join(',');//no i18n
				param.fields += ',id'; //no i18n
				var mxnFieldIdFetch = store.peekRecord('field',rlMeta.mxnfield); //no i18n
				var mxnName = mxnFieldIdFetch && mxnFieldIdFetch.multiselectlookup && mxnFieldIdFetch.multiselectlookup.connectedlookup_apiname ? mxnFieldIdFetch.multiselectlookup.connectedlookup_apiname : '';
				if(mxnName){
					param.fields += ','+mxnName;
					param.fields += ','+mxnName+'.$approval,'+mxnName+'.$approval_state,'+mxnName+'.$approved,'+mxnName+'.$review_process,'+mxnName+'.$review,'+mxnName+'.$stop_processing,'+mxnName+'.$locked_for_me,'+mxnName+'.$editable';
				}
			}
			Lyte.triggerEvent('refreshRlWithId',{id : data.id , fields : param.fields});
			hideAnimatePopup('topBandPopup', true);
		}
	},
	methods : {
		relatedRecordAction : function(actionId , recordId , relatedListId , element ,eventObj,relatedRecord){
			var rlRec = store.peekRecord('related_list',relatedListId);
			var relatedModule = rlRec.module && rlRec.module.api_name? rlRec.module.api_name : rlRec.module;
			var Lyte = window.Lyte;
			if(actionId === "edit"){
				if(rlRec.type === "multiselectlookup"){
					relatedModule = store.peekRecord('module',rlRec.module.id).module_name;
				}
				networkUtils.openUrl(window.location.origin + Lyte.Router.getURL({
					"route" : "crm.tab.module.entity.edit",//No I18N
					"dynamicParams" : [relatedModule,recordId] //No I18N
				}), '_blank'); //No I18N
			}else if(actionId === 'unassign'){
				var md = relatedModule;
				var fm = this.getData('cxPropModule'); //no i18n
				var type;
				if(rlRec.record_operations.delete && rlRec.record_operations.disassociate){
					type = 3;
				}else if(rlRec.record_operations.delete){
					type = 2;
				}else{
					type = 1;
				}
				
				if(md === 'Activities' || md === 'Tasks' ||  md === 'Calls' || md === 'Events' || md === 'Appointments'){ //no i18n
					type = 2;
				}
				if(md === 'Campaigns' && (fm === 'Leads' || fm === 'Contacts') && [1,2,3,4,5,7].indexOf(this.data.cxPropRecord.$campaign_type) !== -1){ //no i18n
					var jsArray = networkUtils.returnDependencyFiles(["crm-campaign-disassociate-popup.js","crm-campaign-detail-utils.js"],ResourceConstants.CRMClient);
					var cssArray = networkUtils.returnDependencyFiles(["crm-campaign-disassociate-popup.css"],ResourceConstants.CRMClient);
					var totalArray = jsArray.concat(cssArray);
					var successFunc = function()
					{
						Lyte.Component.render("crm-campaign-disassociate-popup",{'pModule' : fm, 'campaignType': this.data.cxPropRecord.$campaign_type} ,"#show");
					};
					Lyte.injectResources(totalArray, undefined, successFunc.bind(this));
				}else{
					if(type === 2 && rlRec.api_name !== 'Competitors'){
						var m = relatedModule;
						if(m === "Activities"){
							m = this.data.cxPropRecord.$activity_type;
						}
						if(!Crm.userDetails.permissions.hasOwnProperty("Crm_Implied_Delete_" + m)){
							_cruxUtils.showCustomAlert({ params : { ltPropPrimaryMessage : I18n.getMsg("crm.delete.error"), ltPropSecondaryMessage : I18n.getMsg("crm.security.error"), ltPropButtons : [{"type":"accept","text":I18n.getMsg('crm.button.ok'),"appearance":"primary"}], ltPropButtonPosition : 'right'}, show : function(node){ node.childComp.querySelector('.alertPrimaryMsg').style = "color:#E20000"; } });
							return ;
						}
					}
					if(type === 1 && rlRec.type === "multiselectlookup" && !Crm.userDetails.permissions.hasOwnProperty("Crm_Implied_Delete_" + rlRec.linkingmodule)){
							_cruxUtils.showCustomAlert({ 
								params : { 
									ltPropPrimaryMessage : I18n.getMsg("crm.delete.error"), ltPropSecondaryMessage : I18n.getMsg("crm.security.error"), ltPropButtons : [{"type":"accept","text":I18n.getMsg('crm.button.ok'),"appearance":"primary"}], ltPropButtonPosition : 'right'
								}, show : function(node){ node.childComp.querySelector('.alertPrimaryMsg').style = "color:#E20000"; } });
							return;
					}
					// Lyte.injectResources([networkUtils.returnDependencyFiles(['crm-detail-view-utils.js'], ResourceConstants.CRMClient)], undefined, function () {
						Lyte.registeredMixins["crm-detail-view-utils"].recordDeletePopup(relatedRecord,type,rlRec.type === "multiselectlookup" ? rlRec.connectedmodule : md,fm,this.getData('cxPropRecord'),rlRec); //no i18n
					// }.bind(this));
				}
			}
		},
		relatedListActionCrmHandler : function(actionid , relatedlistId , element , xevent){
			if(actionid === "attachDesktop" && !document.viewentityform){
				document.viewentityform = {module : {value : this.data.cxPropModule} , id : {value : this.data.cxPropEntityId}} ; 
			}
			var _self = this;
			var relatedList = store.peekRecord('related_list',relatedlistId);
			switch(actionid){
				case 'associateMxN' : 
					var res = JSON.parse('{"fldName":""}');//no i18n
					res.fldName = relatedList.mxnfield;
					res.relatedlist = true;
					multiLookupsView.frameMLJSON(res);
					var param = multiLookupsView.getParamJson(res.fldName);
					param.entityId = _self.data.cxPropRecord.id;
					param.relatedlist = true;
					param.searchmodule = relatedList.connectedmodule;
					Crm.trackSpotLightAction("Multi Select Lookup - Add Click",{'Module':param.searchmodule});
					param.select = false;
					param.fldName = relatedList.mxnfield;
					param.linkingmodule = relatedList.linkingmodule;
					param.relid = relatedList.id;
					param.parentModule=_self.data.cxPropModule;
					multiLookupsView.submitMultiLookupForm( "addmore", Crm.getCrmBasePath() + "/Search.do", "topBandPopup", param);//no i18n
					break;
				case 'dissociateMxN' : 
					var res = {};//eslint-disable-line no-redeclare
					res.fldName = relatedList.mxnfield;
					multiLookupsView.frameMLJSON(res);
					var param = multiLookupsView.getParamJson(res.fldName);//eslint-disable-line no-redeclare
					param.entityId = _self.data.cxPropRecord.id;
					param.link = "edit";//no i18n
					param.relatedlist = true;
					param.searchmodule = relatedList.connectedmodule;
					Crm.trackSpotLightAction("Multi Select Lookup - Edit Click",{'Module':param.searchmodule});
					param.select = true;
					param.fldName = relatedList.mxnfield;
					param.linkingmodule = relatedList.linkingmodule;
					param.relid = relatedList.id;
					param.parentModule=_self.data.cxPropModule;
					multiLookupsView.submitMultiLookupForm( "addmore", Crm.getCrmBasePath() + "/Search.do", "topBandPopup", param);//no i18n
				break;
				default : 
				var Lyte = window.Lyte;
				// Lyte.injectResources([networkUtils.returnDependencyFiles(['crm-detail-view-utils.js'], ResourceConstants.CRMClient)], undefined, function () {
					Lyte.registeredMixins["crm-detail-view-utils"].executeRelatedListActions(actionid,relatedList,_self.data.cxPropModule,_self.data.cxPropRecord,xevent); //no i18n
				// });
			}
		}
	}
});


/**
 * @syntax nonYielded
* <crm-relatedlist cx-prop-module="SalesModule1" cx-prop-layout-id="1000000009127" cx-prop-entity-id="111111000000075001" cx-prop-related-lists='[{"visible":true,"module":{"api_name":"Cases","id":"961991000000000159"},"personality_name":"CASESPERSONALITY","record_operations":{"edit":true,"create":true,"bulk_edit":true,"delete":true,"disassociate":true,"assign":true},"type":"custom_lookup","sequence_number":1,"display_label":"Cases","api_name":"cases","fields":[{"lookup":{},"ui_type":1,"api_name":"Subject","enable_colour_code":false,"rollup_summary":{},"field_label":"Subject","formula":{},"id":"961991000000000663","separator":false,"decimal_place":null,"pick_list_values":[]},{"lookup":{},"ui_type":2,"api_name":"Case_Reason","enable_colour_code":false,"rollup_summary":{},"field_label":"Case Reason","formula":{},"id":"961991000000173111","separator":false,"decimal_place":null,"pick_list_values":[]},{"lookup":{},"ui_type":25,"api_name":"Email","enable_colour_code":false,"rollup_summary":{},"field_label":"Email","formula":{},"id":"961991000000000675","separator":false,"decimal_place":null,"pick_list_values":[]}],"id":"961991000002824039"}]' cx-prop-rl-data='{"961991000002824039":[{"Email":"werw@ewfw.we","Case_Reason":"test","Subject":"Case1"},{"Email":"sefw@wefe.wer","Case_Reason":"fix","Subject":"Case2"}]}'></crm-relatedlist>  
*/