/**
 * @component crux-messagebox
 * @author authorName
 * @version 1.0.0
 * @summary summary about the component if any
 * @notes notes about the component if any
 */
Lyte.Component.register("crux-messagebox", {
_template:"<template tag-name=\"crux-messagebox\"> <lyte-messagebox on-close=\"{{method('setClose')}}\" on-show=\"{{method('setShow')}}\" lt-prop-message=\"{{cxPropMessage}} \" lt-prop-show=\"{{lbind(cxPropShow)}}\" lt-prop-type=\"{{cxPropType}} \" lt-prop-class=\"{{cxPropClass}}\" lt-prop-duration=\"{{cxPropDuration}}\" lt-prop-offset=\"{{cxPropOffset}}\" lt-prop-transition=\"{{cxPropTransition}}\" lt-prop-yield=\"true\" lt-prop-animation=\"{{cxPropAnimation}}\" lt-prop-close-manually=\"{{cxPropCloseManually}}\"> <template is=\"registerYield\" yield-name=\"messageboxYield\"> <span> <template is=\"if\" value=\"{{expHandlers(expHandlers(cxPropYield,'!'),'&amp;&amp;',expHandlers(cxPropMarkdown,'!'))}}\"><template case=\"true\"> {{cxPropMessage}} </template><template case=\"false\"> {{unescape(if(cxPropMarkdown,markdown(cxPropMessage),cxPropMessage))}} </template></template> </span> </template> </lyte-messagebox> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1,1]},{"type":"if","position":[1,1],"cases":{"true":{"dynamicNodes":[{"type":"text","position":[1]}]},"false":{"dynamicNodes":[{"type":"text","position":[1]}]}},"default":{}}]},{"type":"componentDynamic","position":[1]}],
_observedAttributes :["cxPropShow","cxPropMessage","cxPropType","cxPropDuration","cxPropOffset","cxPropTransition","cxPropYield","cxPropClass","cxPropMarkdown","cxPropCloseManually"],

	data : function(){
		return {
			/**
			 * @componentProperty { boolean } cxPropShow=false
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropShow : Lyte.attr("boolean", {default : false}),
			/**
			 * @componentProperty { string } cxPropMessage
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropMessage : Lyte.attr("string", {default : ""}) ,
			/**
			 * @componentProperty { string } cxPropType
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropType : Lyte.attr("string", {default : "success"}),
			/**
			 * @componentProperty { string } cxPropDuration
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropDuration: Lyte.attr("string", {default : "2000"}),
			/**
			 * @componentProperty { object } cxPropOffset
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropOffset : Lyte.attr("object"),
			/**
			 * @componentProperty { string } cxPropTransition
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropTransition: Lyte.attr("string", {default : ' {"animation" : "fadeIn" } ' }),
			/**
			 * @componentProperty { boolean } cxPropYield=false
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropYield: Lyte.attr("boolean", {default : false}),
			/**
			 * @componentProperty { string } cxPropClass
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropClass: Lyte.attr("string", {default : "cxAlertWrapper"}),
			/**
			 * @componentProperty { boolean } cxPropMarkdown=false
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropMarkdown: Lyte.attr("boolean", {default : false}),
			/**
			 * @componentProperty { boolean } cxPropCloseManually=false
			 * @author authorName
			 * @version 1.0.0
			 */
			cxPropCloseManually: Lyte.attr("boolean", {default : false})
			}		
	},
	init: function(){
		if(this.$node.getData("cxPropClass")!= "cxAlertWrapper")
		{
			this.$node.setData("cxPropClass",this.$node.getData("cxPropClass")+" "+"cxAlertWrapper");
		}
	},
	actions : {
		// Functions for event handling
	},
	methods : {
		setShow: function()
		{
			var node=this.$node.querySelector('lyte-messagebox');
			if(this.getMethods("onShow")){
				node && this.executeMethod("onShow",node.component);
			 }
		},
		setClose: function()
		{
			var node=this.$node.querySelector('lyte-messagebox');
			if(this.getMethods("onClose")){
				node && this.executeMethod("onClose",node.component);
			 }
		}
	}
});

/**
 * @syntax nonYielded
 * <crux-messagebox></crux-messagebox>
 */
