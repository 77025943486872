Lyte.Mixin.register("crm-crux-create-base-mixin", {
    checkAndFetchMetaData: async function (layoutCompData) {
        try {
            let defaultKeysObject = {
                isModuleMetaDataEmpty: 'cxPropModuleData',
                isSectionsMetaDataEmpty: 'cxPropLayoutSections'
            };
            if (layoutCompData.cxPropLayoutRulesRequired || this.data.cxPropLayoutRulesRequired) {
                defaultKeysObject.isLayoutRulesMetaDataEmpty = 'cxPropLayoutRules';
            }
            if (layoutCompData.cxPropValidationRulesRequired || this.data.cxPropValidationRulesRequired) {
                defaultKeysObject.isValidationRulesMetaDataEmpty = 'cxPropValidationRules';
            }
            for (var emptyKey in defaultKeysObject) {
                if (this.isEmptyObj(layoutCompData[defaultKeysObject[emptyKey]])) {
                    this.data[emptyKey] = true;
                }
            }
            if (this.data.cxPropRecordId && ['edit', 'clone'].includes(this.data.cxPropCurrentPage) && this.isEmptyObj(layoutCompData.cxPropRecordData)) {
                this.data.isRecordDataEmpty = true;
            }
            let haveAllDefaultMetaData = !this.data.isModuleMetaDataEmpty && !this.data.isSectionsMetaDataEmpty &&
                !this.data.isLayoutRulesMetaDataEmpty && !this.data.isValidationRulesMetaDataEmpty && !this.data.isRecordDataEmpty;
            this.setData('haveAllDefaultMetaData', haveAllDefaultMetaData);//no i18n
            if (haveAllDefaultMetaData) {
                if (this.data.cxPropCurrentPage === 'create' && this.isEmptyObj(layoutCompData.cxPropFormData)) {
                    layoutCompData.cxPropFormData = store.createRecord(layoutCompData.cxPropModuleId, {}, true);
                }
                return;
            }
            this.setData('showLoading', true);//no i18n
            let promiseObj = {};
            if (this.data.isModuleMetaDataEmpty) {
                if (layoutCompData.cxPropModuleId) {
                    promiseObj.moduleDetail = await this.fetchModuleMetaData(layoutCompData.cxPropModuleId);
                    if (!promiseObj.moduleDetail) {
                        promiseObj.moduleDetail = {};
                    }
                    if (promiseObj.moduleDetail.success && promiseObj.moduleDetail.success[0]) {
                        layoutCompData.cxPropModuleData = promiseObj.moduleDetail.success[0];
                        this.data.refetchLayoutMetaData = true;
                        delete this.data.isModuleMetaDataEmpty;
                    } else if (promiseObj.moduleDetail.isExceptionOccured) {
                        this.setLayoutComponentError({ isExceptionOccured: true, errorDetails: promiseObj.moduleDetail.moduleFetchException, primaryErrorMessage: 'Exception occured while trying to fetch Module meta data' });//no i18n
                    } else if (promiseObj.moduleDetail.failure) {
                        this.setLayoutComponentError({ errorDetails: promiseObj.moduleDetail.failure, primaryErrorMessage: 'Request failed / failure response received for GET Module meta data request' });//no i18n
                    }
                } else {
                    this.setLayoutComponentError({ primaryErrorMessage: 'Mandatory module meta data / moduleId for rendering crux create form is missing' });//no i18n
                }
            }
            if (this.data.isRecordDataEmpty) {
                promiseObj.recordDataDetails = await this.fetchEntityRecordData(layoutCompData);
                if (promiseObj.recordDataDetails && promiseObj.recordDataDetails.success) {
                    let successValue = promiseObj.recordDataDetails.success[0] || promiseObj.recordDataDetails.success;
                    if (successValue) {
                        layoutCompData.cxPropFormData = layoutCompData.cxPropRecordData = successValue;
                        delete this.data.isRecordDataEmpty;
                    }
                    if (this.isEmptyObj(successValue)) {
                        this.setLayoutComponentError({ primaryErrorMessage: 'Empty response received for GET Entity record request' });//no i18n
                    } else if (layoutCompData.cxPropFormData && layoutCompData.cxPropFormData.Layout && layoutCompData.cxPropFormData.Layout.id) {
                        layoutCompData.cxPropLayoutId = layoutCompData.cxPropFormData.Layout.id;
                    }
                }
            }
            if ((this.data.isSectionsMetaDataEmpty || this.data.refetchLayoutMetaData) && !this.isEmptyObj(layoutCompData.cxPropModuleData)) {
                promiseObj.layoutDetail = await this.fetchLayoutMetaData(layoutCompData, layoutCompData.cxPropLayoutId);
                if (!promiseObj.layoutDetail) {
                    promiseObj.layoutDetail = {};
                }
                if (promiseObj.layoutDetail && promiseObj.layoutDetail.success && promiseObj.layoutDetail.success[0]) {
                    let layoutCacheResponse = promiseObj.layoutDetail.success[0] || {},
                        layoutResponse = layoutCacheResponse.layouts && layoutCacheResponse.layouts.filter((f) => { return f.id === promiseObj.layoutDetail.layoutId; })[0] || {},
                        currentLayoutSections = layoutResponse && layoutResponse.sections || [];
                    layoutCompData.cxPropLayoutSections = currentLayoutSections;
                    layoutCompData.cxPropLayoutData = layoutResponse;
                    layoutCompData.cxPropLayoutId = layoutResponse.id;
                    delete this.data.isSectionsMetaDataEmpty;
                    delete this.data.refetchLayoutMetaData;
                } else if (promiseObj.layoutDetail.isExceptionOccured) {
                    this.setLayoutComponentError({ isExceptionOccured: true, errorDetails: promiseObj.layoutDetail.layoutFetchException, primaryErrorMessage: 'Exception occured while trying to fetch Layout meta data' });//no i18n
                } else if (promiseObj.layoutDetail.failure) {
                    this.setLayoutComponentError({ errorDetails: promiseObj.layoutDetail.failure, primaryErrorMessage: 'Request failed / failure response received for GET Layout meta data request' });//no i18n
                }
            }
            if (this.data.cxPropCurrentPage === 'create' && this.isEmptyObj(layoutCompData.cxPropFormData)) {
                layoutCompData.cxPropFormData = store.createRecord(layoutCompData.cxPropModuleId, {}, true);
            }
            let rulesPromise = {};
            if (this.data.isLayoutRulesMetaDataEmpty) {
                rulesPromise.layoutRulesDetails = this.fetchLayoutRuleData(layoutCompData, layoutCompData.cxPropLayoutId);
            }
            if (this.data.isValidationRulesMetaDataEmpty) {
                rulesPromise.validationRulesDetails = this.fetchValidationRuleData(layoutCompData, layoutCompData.cxPropLayoutId);
            }
            let rulesPromiseResponse = await Lyte.resolvePromises(rulesPromise);
            if (rulesPromiseResponse.layoutRulesDetails && rulesPromiseResponse.layoutRulesDetails.success && rulesPromiseResponse.layoutRulesDetails.success.layout_rule) {
                layoutCompData.cxPropLayoutRules = rulesPromiseResponse.layoutRulesDetails.success.layout_rule;
                delete this.data.isLayoutRulesMetaDataEmpty;
            }
            if (rulesPromiseResponse.validationRulesDetails && rulesPromiseResponse.validationRulesDetails.success && rulesPromiseResponse.validationRulesDetails.success.length) {
                layoutCompData.cxPropValidationRules = rulesPromiseResponse.validationRulesDetails.success;
                delete this.data.isValidationRulesMetaDataEmpty;
            }
            await this.actual_init();
            await this.actual_didConnect();
        } catch (error) {
            this.setLayoutComponentError({ errorDetails: error, isExceptionOccured: true });
        }
    },
    setLayoutComponentError: function (customData) {
        customData = customData || {};
        this.setDefautMessageDetails();
        let cxInternalObj = this.data.cxPropLayoutComponentData.cxInternalUtilityObj;
        Lyte.objectUtils(cxInternalObj.commonMessageData, "add", "showCommonMessage", true);//No I18n
        let errDetails = (customData.errorDetails || {}), errMessageSecondary, errorStack;
        if (customData.isExceptionOccured) {
            errMessageSecondary = errDetails.message;
            errorStack = errDetails.stack;
        } else {
            errMessageSecondary = this.isEmptyObj(errDetails) ? '' : errDetails;
        }
        let finalError = `${customData.primaryErrorMessage || ''}
		
		${errMessageSecondary || ''}
		
		${errorStack || ''}`;
        Lyte.objectUtils(cxInternalObj.commonMessageData, "add", "messageClassType", 'cxPropMessageTypeFailure');//No I18n
        Lyte.objectUtils(cxInternalObj.commonMessageData, "add", "message", finalError);//no i18n
        this.setData('showLoading', false);
    },
    setDefautMessageDetails: function () {
        let layoutCompData = this.data.cxPropLayoutComponentData;
        if (!layoutCompData) {
            Lyte.objectUtils(this.data, "add", "cxPropLayoutComponentData", {});//No I18n
        }
        layoutCompData = this.data.cxPropLayoutComponentData;
        if (!layoutCompData.cxInternalUtilityObj) {
            Lyte.objectUtils(layoutCompData, "add", "cxInternalUtilityObj", {});//No I18n
        }
        if (!layoutCompData.cxInternalUtilityObj.commonMessageData) {
            Lyte.objectUtils(layoutCompData.cxInternalUtilityObj, "add", "commonMessageData", {});//No I18n
        }
    },
    isEmptyObj: function (obj) {
        try {
            var tempObj = null;
            if (!obj || obj === "null" || obj === "NULL" || obj === "empty" || obj === "EMPTY" || obj === "undefined" || obj === "UNDEFINED") { //No I18N
                return true;
            } else if (typeof obj !== "object" && typeof obj === "string") { //No I18N
                tempObj = obj.replace(/[ ]/g, "");
            } else if (typeof obj === "object") { //No I18N
                return Object.keys(obj).length < 1 ? true : false;
            }
            if (tempObj === "") {
                return true;
            }
            return false;
        }
        catch (e) {
            return true;
        }
    },
    getDefaultLayoutDetails: function (moduleData, profileName, cxPropLayoutId) {
        let currentProfileName = profileName || (typeof Crm !== 'undefined' && Crm.userDetails.PROFILE_NAME) || "Administrator",//no i18n
            layDet = moduleData.layouts || [],
            currentLayoutName,
            currentLayoutId,
            layoutddValues = [],
            showIntegrationLayoutDD = false,
            module_name = moduleData.module_name;
        if (layDet && layDet.length) {
            layDet = Lyte.deepCopyObject(layDet);//ZCRM-118843
            layDet = layDet.sort((layout1, layout2) => {
                let name1 = layout1.name.toUpperCase(),
                    name2 = layout2.name.toUpperCase();
                if (name1 < name2) {
                    return -1;
                }
                if (name1 > name2) {
                    return 1;
                }
                return 0;
            });
        }
        let len = layDet && layDet.length || 0;
        for (var i = 0; i < len; i++) {
            if (layDet[i].status >= 0 || layDet[i].status === true || layDet[i].status === "active") {
                showIntegrationLayoutDD = module_name === "Campaigns" && layDet[i].status === "active" && layDet[i].source === "campaign_integration" ? true : showIntegrationLayoutDD;//no i18n
                let isValidLayout = false;
                if (layDet[i].profiles) {
                    var profL = layDet[i].profiles.length, isDefault = false;
                    for (var c = 0; c < profL; c++) {
                        if (currentProfileName === layDet[i].profiles[c].name) {
                            isValidLayout = true;
                            if (layDet[i].profiles[c].default) {
                                currentLayoutName = layDet[i].name;
                                currentLayoutId = layDet[i].id;
                                isDefault = true;
                                break;
                            }
                        }
                    }
                    if (isValidLayout) {
                        let layoutDetailsObj = {
                            uservalue: layDet[i].name,
                            systemvalue: layDet[i].id,
                            id: layDet[i].id,
                            isDefault: isDefault,
                            status: layDet[i].status,
                            source: layDet[i].source
                        };
                        layoutddValues.push(layoutDetailsObj);
                    }
                }
            }
        }
        if (cxPropLayoutId) {
            currentLayoutId = cxPropLayoutId;
            let currentLayoutDetails = layoutddValues.filter((option) => { return option.id === currentLayoutId; })[0];
            currentLayoutName = (currentLayoutDetails && currentLayoutDetails.uservalue) || currentLayoutName;
        }
        if (module_name === "Campaigns" && cxPropLayoutId) {
            let currLayout = layoutddValues.filter((f) => { return f.id === cxPropLayoutId; })[0];
            if (currLayout) {
                currentLayoutName = currLayout.uservalue;
                currentLayoutId = currLayout.id;
            }
        }
        if (!currentLayoutId && layoutddValues && layoutddValues.length) { //when none of the layout is default layout for the profile
            if (module_name === "Campaigns") {
                let layoutToRender = layoutddValues.filter((lay) => { return lay.source === "crm"; })[0];
                layoutToRender = layoutToRender || layoutddValues[0];
                currentLayoutName = layoutToRender.name;
                currentLayoutId = layoutToRender.id;
                layoutToRender.isDefault = true;
            } else {
                currentLayoutName = layoutddValues[0].name;
                currentLayoutId = layoutddValues[0].id;
                layoutddValues[0].isDefault = true;
            }
        }
        return { layoutddValues, showIntegrationLayoutDD, currentLayoutName, currentLayoutId };
    },
    getLayoutDomNode: function () {
        return this.data.cxPropLayoutComponentData && this.data.cxPropLayoutComponentData.layoutComponentDomNode;
    },
    registerUtilityMethods: function (layoutComponentDomNode) {
        /**
         * This utility function can be used to set the data into the form.
         * @utility setFormData
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.setFormData = (customData) => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            layoutComponentDomNode.setFormData(customData);
        };
        /**
         * This utility function can be used to get the data of the form.
         * @utility getFormData
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.getFormData = () => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            return layoutComponentDomNode.getFormData();
        };
        this.$node.getFormDirtyAttributes = () => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            return layoutComponentDomNode.getFormDirtyAttributes();
        };
        /**
         * This utility function can be used to get the subform data of the form.
         * @utility getSubFormData
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.getSubFormData = (customData) => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            customData = customData || {};
            return layoutComponentDomNode.getSubFormData(customData);
        };
        /**
         * This utility function can be used to validate the form.
         * @utility validateForm
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.validateForm = (customData) => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            customData = customData || {};
            return layoutComponentDomNode.validateForm(customData);
        };
        /**
         * This utility function can be used to validate the subform.
         * @utility validateSubform
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.validateSubform = (customData) => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            customData = customData || {};
            return layoutComponentDomNode.validateSubform(customData);
        };
        /**
         * This utility function can be used to destroy the form.
         * @utility destroyComponent
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.destroyComponent = () => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            layoutComponentDomNode.destroyComponent();
        };
        /**
         * This utility function can be used to get the class of the element which holds the form contents.
         * @utility getContentWrapperClass
         * @author kuralarasan.s
         * @version 1.0.0
         */
        this.$node.getContentWrapperClass = () => {
            if (!layoutComponentDomNode) {
                layoutComponentDomNode = this.getLayoutDomNode();
            };
            if (!layoutComponentDomNode) { return; };
            layoutComponentDomNode.getContentWrapperClass();
        };
    },
    setFieldOfLookupData: function (customData) {
        customData = customData || {};
        let folDetails = customData.fieldOfLookupDetails || [],
            lookupRecord = customData.selectedLookupRecord || {},
            setDataObject = {};
        //originalValues;
        if (this.isEmptyObj(lookupRecord)) {
            return;
        }
        /*
        if (lookupRecord && lookupRecord._$ && lookupRecord._$.original) {
            originalValues = lookupRecord._$.original;
        }
        originalValues = originalValues || {};
        */
        folDetails.forEach((details) => {
            let { currentFormField, lookupModuleMapField } = details,
                fieldValue = lookupRecord[lookupModuleMapField.api_name];
            /*
            if(originalValues.hasOwnProperty(lookupModuleMapField.api_name)){
                fieldValue = originalValues[lookupModuleMapField.api_name];
            }
            */
            setDataObject[currentFormField] = fieldValue;
        });
        setDataObject = setDataObject || {};
        if (customData.layoutComponentDomNode) {
            customData.layoutComponentDomNode.setFormData({ cruxFormData: setDataObject });
        }
    },
    setSaveResponseInMessage: function (detailsObj, layoutCompData) {
        if (detailsObj && detailsObj.saveResponse) {
            let utilityObj = layoutCompData.cxInternalUtilityObj;
            this.setDefautMessageDetails();
            Lyte.objectUtils(utilityObj.commonMessageData, "add", "showCommonMessage", true);//No I18n
            let recordActionType = "", recordStateType = "";
            switch (utilityObj.currentPage) {
                case 'edit':
                    recordActionType = "updated"; recordStateType = "Updated record";
                    break;
                case 'clone':
                    recordActionType = "cloned"; recordStateType = "Newly cloned record";
                    break;
                default:
                    recordActionType = "created"; recordStateType = "Newly created record";
            }
            let msg = `Entity Record got ${recordActionType} succesfully. ${recordStateType} Id : ${detailsObj.saveResponse.id}`;
            Lyte.objectUtils(utilityObj.commonMessageData, "add", "message", msg);//No I18n
            Lyte.objectUtils(utilityObj.commonMessageData, "add", "messageClassType", 'cxPropMessageTypeSuccess');//No I18n
        }
    },
    showHideLoadingDiv: function (show) {
        if (typeof commonUtils !== "undefined" && commonUtils.showHideLoadingDiv) {
            commonUtils.showHideLoadingDiv(show);
        }
    },
    serializeFormDataBeforeSave: function (formData, formFieldList) {
        if (formData && (!formData.$ || !formData.$.model)) {
            for (var fieldKey in formData) {
                let fieldList = formFieldList[fieldKey], fieldValue = formData[fieldKey];
                if (fieldList && fieldList.fieldDataType === "multiselectpicklist" &&
                    fieldList.json_type === "jsonarray" && fieldValue && typeof fieldValue === "string") {
                    try {
                        formData[fieldKey] = JSON.parse(fieldValue);
                    } catch (exe) {
                        formData[fieldKey] = fieldValue;
                    }
                }
            }
        }
        return formData;
    },
    onFormAfterSave: function (layoutCompData, subformDetails = {}, customData) {
        layoutCompData.layoutComponentDomNode.destroyComponent();
        this.lookupCallBackHandler(customData, subformDetails);
    },
    onFormCancel: function (layoutCompData, subformDetails = {}, customData) {
        layoutCompData.layoutComponentDomNode.destroyComponent();
        this.lookupCallBackHandler(customData, subformDetails);
    },
    getCurrentLookupNode: function (customData = {}) {
        let { cruxLookupElmId, fromSubform, currentFieldNode } = customData,
            qSel = `${fromSubform ? `#${cruxLookupElmId}` : 'crux-lookup-component'}`,
            nodeElem = currentFieldNode ? currentFieldNode : this.$node;
        return nodeElem.querySelector(qSel);
    },
    showLookupModal: function (customData = {}) {
        let lookupNode = this.getCurrentLookupNode(customData);
        if (lookupNode) {
            lookupNode.showLookup();
        }
    },
    hideLookupDropdown: function (customData = {}) {
        let { cruxLookupElmId, fromSubform, currentFieldNode } = customData;
        let qSel = `${fromSubform ? `#${cruxLookupElmId}` : 'crux-lookup-component'} lyte-autocomplete lyte-dropdown`,//no i18n
            nodeElem = currentFieldNode ? currentFieldNode : this.$node,
            ddNode = nodeElem.querySelector(qSel);
        if (ddNode) {
            ddNode.close();
        }
    },
    hideLookupModal: function (customData = {}) {
        let lookupNode = this.getCurrentLookupNode(customData);
        if (lookupNode) {
            lookupNode.close();
        }
    },
    lookupCallBackHandler: function (dataObj = {}, subformData = {}) {
        let currentButtonObj = dataObj.currentButtonObj,
            fieldMeta = subformData.cxPropFieldData || this.data.cxPropFieldData,
            newRecordDetails = dataObj.quickCreatedRecord;
        if (["cancel", "save"].includes(currentButtonObj.name) && (this.quickCreateFromModal || currentButtonObj.name === "save")) {
            this.showLookupModal(subformData);
        } else if (currentButtonObj.name === "saveandassociate" && newRecordDetails) {
            let layoutCompData = this.data.cxPropLayoutComponentData,
                lookupModuleId = fieldMeta.lookup.module.id,
                currentApiname = fieldMeta.api_name,
                lookupModuleInfo = layoutCompData.cxInternalUtilityObj.lookupModuleMetaInfo,
                fieldValue = { id: newRecordDetails.id };

            if (lookupModuleInfo[lookupModuleId] && lookupModuleInfo[lookupModuleId].display_field) {
                fieldValue.name = newRecordDetails[lookupModuleInfo[lookupModuleId].display_field.api_name];
            }
            if (subformData && subformData.fromSubform && subformData.recordObj) {
                Lyte.Component.set(subformData.recordObj, currentApiname, fieldValue);
            } else {
                let formDataObj = { cruxFormData: {} };
                formDataObj.cruxFormData[currentApiname] = fieldValue;
                if (layoutCompData.layoutComponentDomNode) {
                    layoutCompData.layoutComponentDomNode.setFormData(formDataObj);
                }
            }
        }
    }
});