/**
 * Used to display an error message below crux elements
 * @component crux-error-message
 * @author anuja.manoharan
 * @version 1.0.0
 */
Lyte.Component.register("crux-error-message", {
_template:"<template tag-name=\"crux-error-message\"> <template is=\"if\" value=\"{{expHandlers(cxPropErrorOnHovercard,'!')}}\"><template case=\"true\"> <template is=\"if\" value=\"{{cxPropErrorYield}}\"><template case=\"true\"><lyte-yield yield-name=\"errorYield\" error-message=\"{{errorMessage}}\" cx-field=\"{{cxPropField}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\"></lyte-yield></template><template case=\"false\"><span id=\"{{cxPropId}}\" class=\"cruxErrMsg cruxErrorMsgDesc {{cxPropErrorSpanClass}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\">{{unescape(errorMessage)}}</span></template></template> </template><template case=\"false\"> <lyte-hovercard lt-prop-auto-show=\"true\" id=\"cx_error_hovercard\" lt-prop-popover-wrapper-class=\"cxErrorHoverCard\" lt-prop-origin-elem=\"{{cxPropOriginElem}}\" lt-prop-max-width=\"550px\"> <template is=\"registerYield\" yield-name=\"hoverCardYield\"> <lyte-hovercard-content> <template is=\"if\" value=\"{{cxPropErrorYield}}\"><template case=\"true\"><lyte-yield yield-name=\"errorYield\" error-message=\"{{errorMessage}}\" cx-field=\"{{cxPropField}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\"></lyte-yield></template><template case=\"false\"><span id=\"{{cxPropId}}\" class=\"cxErrorHoverCardMsg {{cxPropErrorSpanClass}}\" data-zcqa=\"{{concat(cxPropErrorZcqaPrefix,cxPropField.api_name,cxPropErrorZcqaSuffix)}}\">{{unescape(errorMessage)}}</span></template></template> </lyte-hovercard-content> </template> </lyte-hovercard> </template></template> </template>",
_dynamicNodes : [{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"if","position":[1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"insertYield","position":[0]}]},"false":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"text","position":[0,0]}]}},"default":{}}]},"false":{"dynamicNodes":[{"type":"attr","position":[1]},{"type":"registerYield","position":[1,1],"dynamicNodes":[{"type":"attr","position":[1,1]},{"type":"if","position":[1,1],"cases":{"true":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"insertYield","position":[0]}]},"false":{"dynamicNodes":[{"type":"attr","position":[0]},{"type":"text","position":[0,0]}]}},"default":{}},{"type":"componentDynamic","position":[1]}]},{"type":"componentDynamic","position":[1]}]}},"default":{}}],
_observedAttributes :["cxPropOriginElem","cxPropErrorOnHovercard","errorMessage","cxPropId","cxPropErrorYield","cxPropField","cxPropErrorZcqaPrefix","cxPropErrorZcqaSuffix","cxPropErrorSpanClass","cxPropErrorIconClass"],
//No I18n
	data : function(){
		return {
			cxPropOriginElem : Lyte.attr("string"), //No I18n
			cxPropErrorOnHovercard : Lyte.attr( 'boolean' , {default : false} ),//no i18n
			errorMessage : Lyte.attr("string"), //No I18n
			/**
			 * @componentProperty { string } cxPropId
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * id set to the span tag
			 */
			cxPropId: Lyte.attr("string", {"default": ""}), //NO i18n
			/**
			 * @componentProperty { boolean } cxPropErrorYield=false
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * user can set their own error elements instead of a plain message
			 */
			cxPropErrorYield : Lyte.attr("boolean", {default : false}),//No I18n
			/**
			 * @componentProperty { object } cxPropField
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * in yield cases, this is passed back and for non-yield cases this is used for zcqa
			 */
			cxPropField : Lyte.attr("object"),//No I18n
			/**
			 * @componentProperty { string } cxPropErrorZcqaPrefix
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * set as prefix to the zcqa
			 */
			cxPropErrorZcqaPrefix : Lyte.attr("string", {default : ""}),//No I18n
			/**
			 * @componentProperty { string } cxPropErrorZcqaSuffix
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * set as suffix to the zcqa
			 */
			cxPropErrorZcqaSuffix : Lyte.attr("string", {default : "Error"}),//No I18n
			/**
			 * @componentProperty { string } cxPropErrorSpanClass
			 * @author anuja.manoharan
			 * @version 1.0.0
			 * class set to the span
			 */
			cxPropErrorSpanClass : Lyte.attr("string"),//No I18n
			cxPropErrorIconClass : Lyte.attr('string')
		}
	},
	observeMessage : function(){
        var cruxErrorNode = this.$node;
        var errorMsgSpan = cruxErrorNode.querySelector("span"); //NO I18N
        if(errorMsgSpan && this.data.errorMessage && !this.data.cxPropErrorYield && (errorMsgSpan.scrollWidth > cruxErrorNode.offsetWidth)){
            $L(cruxErrorNode).addClass("cxErrorMsgMultiLines");    //NO I18N
        }
	}.observes("errorMessage").on("didConnect")//No I18n
});

/**
 * @syntax nonYielded
 * <crux-error-message></crux-error-message>
 */
